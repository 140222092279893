import api from "./api-service";

const baseUrl = "therapists";

export const findSettings = () => api().get(`${baseUrl}/settings`);

export const saveSettings = (data) => api().post(`${baseUrl}/settings`, data);

export const filterToAppointment = (filter) =>
  api().get(`${baseUrl}/appointment/filter/${filter}`);

export const findFirstTenTherapists = () =>
  api().get(`${baseUrl}/appointment/first-therapists`);

export const getAppointmentChecklist = () =>
  api().get(`${baseUrl}/settings/appointment/checklist`);

export const getProfileSettings = () =>
  api().get(`${baseUrl}/settings/profile`);

export const saveProfileSettings = (data) =>
  api().post(`${baseUrl}/settings/profile`, data);

export const findNextTenPublicTherapists = (
  page,
  searchWord,
  location,
  userId
) => {
  let queryParams = new URLSearchParams();

  if (page) queryParams.append("page", page);
  if (searchWord) queryParams.append("searchWord", searchWord);
  if (location) queryParams.append("location", location);
  if (userId) queryParams.append("userId", userId);

  return api().get(`${baseUrl}/appointments/public?${queryParams.toString()}`);
};
