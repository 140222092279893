<template>
  <v-card flat>
    <v-card-title> Estou visível no agendamento público? </v-card-title>
    <v-card-text>
      <icon-value
        v-bind="checklistItemProps(checklist.hasAddress)"
        value="Endereço completo cadastrado"
      />
      <icon-value
        v-bind="checklistItemProps(checklist.allowedToShow)"
        value="Permitido agendamento público"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {
  saveSettings,
  getAppointmentChecklist,
} from "@/services/external/therapists-service";

export default {
  name: "TherapistSettingsAppointments",
  components: {
    IconValue: () => import("@/components/shared/IconValue"),
  },
  data: () => ({
    checklist: {},
  }),
  created() {
    this.fillChecklist();
  },
  methods: {
    async fillChecklist() {
      try {
        const { data } = await getAppointmentChecklist();
        this.checklist = data;
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        await saveSettings(this.settings);
        this.$toasted.global.success({
          message: "Configurações salvas com sucesso.",
        });
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    checklistItemProps(item) {
      return item
        ? {
            icon: "check",
            iconColor: "green",
          }
        : {
            icon: "close",
            iconColor: "red",
          };
    },
  },
};
</script>

<style scoped></style>
